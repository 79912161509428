import React, { useState } from "react";
import axios from "axios";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Stack,
} from "@mui/material";

import { NGROK } from "../../APIs";
import useUserStore from "../../services/userStore";

const ResourceRequestForm = ({
  resourceName,
  resourcePath,
  selectedUser,
  resourceId,
  resourceType,
  computer,
  sessionRequest,
  tenantName,
  publisherId,
  isWhiteswanAccess,
  isFromEndPointApp = false,
}) => {
  const [open, setOpen] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");

  const userData = useUserStore((state) => state.user);

  const [accessType, setAccessType] = useState("DYNAMIC");
  const [selectedTime, setSelectedTime] = useState("");
  const isServer = computer?.belongedToUser
    ? computer.belongedToUser === userData.email
      ? false
      : true
    : true;
  const isDeviceSSh = (device) => {
    return (
      device.OperatingSystem.toLowerCase().includes("linux") ||
      device.OperatingSystem.toLowerCase().includes("macos")
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setRequestMessage("");
    setSelectedTime("");
    setOpen(false);
  };

  const getStringBeforeAtSymbol = (str) => {
    const atIndex = str.indexOf("@");
    return atIndex !== -1 ? str.substring(0, atIndex) : str;
  };

  const handleInputChange = (value) => {
    const regex = /^(?!0)[0-9\b]+$/;
    if (value?.length > 3 || value.includes("e") || value === 0) return;

    if (regex.test(value)) {
      setSelectedTime(value);
    } else if (!value) setSelectedTime("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "+" || event.key === "-") {
      event.preventDefault();
    }
  };

  const handleAccessType = (value) => {
    if (value === "PERMANENT") setSelectedTime("");
    setAccessType(value);
  };

  const handleSubmit = async () => {
    const username = getStringBeforeAtSymbol(userData.email);
    const data = {
      userId: sessionRequest ? userData.userId : selectedUser.id,
      username: sessionRequest ? username : selectedUser?.cn,
      email:
        isFromEndPointApp && isDeviceSSh(computer)
          ? userData.email
          : sessionRequest
            ? userData.email
            : selectedUser?.email,
      resourceType: sessionRequest ? "GROUP" : resourceType,
      resourceId,
      resourceName,
      computerName:
        isFromEndPointApp && isDeviceSSh(computer)
          ? sessionRequest
            ? userData.email
            : selectedUser?.email
          : computer?.dNSHostName,
      resourcePath: resourcePath || "",
      requestMessage,
      requestStatus: "PENDING",
      selectedTime: selectedTime,
      computerId: computer?.id,
      createdAt: new Date().toISOString(),
      privilegeLevel: accessType,
      tenantName,
      publisherId,
      userEmail: userData.email,
      toConnect: sessionRequest ? true : false,
      isWhiteswanAccess,
      isServer,
    };
    try {
      await axios.post(`${NGROK}/api/access/create`, data);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  // const isRequestBtnDisabled = () => {
  //   return computer.noUsers ||
  //     (sessionRequest &&
  //       computer.OperatingSystem.toLowerCase().includes("linux")) ||
  //     (sessionRequest && computer.OperatingSystem.toLowerCase().includes("mac"))
  //     ? true
  //     : false;
  // };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        {sessionRequest ? "Request connect access" : "Request access"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "18px" }}>
          Access request to {resourceName}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={4}>
            <FormControl component="fieldset">
              <Typography variant="h6">Access Type:</Typography>
              <RadioGroup
                aria-label="access-type"
                name="access-type"
                value={accessType}
                onChange={(e) => handleAccessType(e.target.value)}
                row
              >
                <FormControlLabel
                  value="PERMANENT"
                  control={<Radio />}
                  label="Permanent"
                />
                <FormControlLabel
                  value="DYNAMIC"
                  control={<Radio />}
                  label="Timed"
                />
              </RadioGroup>
            </FormControl>

            <Typography variant="h6">
              Computer Name: {computer?.dNSHostName}
            </Typography>

            <Stack spacing={2} mt={3}>
              <Typography variant="h6">Access justification:</Typography>
              <TextField
                maxRows={5}
                minRows={5}
                inputProps={{ maxLength: 150 }}
                multiline
                value={requestMessage}
                onChange={(e) => setRequestMessage(e.target.value)}
              />
            </Stack>
            {accessType === "DYNAMIC" ? (
              <Stack spacing={2}>
                <Typography variant="h6">Time:</Typography>
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]+",
                  }}
                  onKeyPress={handleKeyPress}
                  value={selectedTime}
                  type="text"
                  fullWidth
                  size="small"
                  label={"Enter time in minutes"}
                  onChange={(e) => handleInputChange(e.target.value)}
                />
              </Stack>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={accessType === "DYNAMIC" && !selectedTime}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResourceRequestForm;
