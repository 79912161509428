import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const RadioSelection = ({ setRadioValue, radioValue, selectedGroup, os }) => {
  const disabledOU = selectedGroup && radioValue !== "OU";
  const disabledPatterns = selectedGroup && radioValue === "OU";

  const handleRadio = (e) => {
    setRadioValue(e.target.value);
  };

  return (
    <FormControl>
      <FormLabel
        sx={{ textAlign: "center" }}
        id="demo-row-radio-buttons-group-label"
      >
        Select Group Criteria
      </FormLabel>
      <RadioGroup
        disabled
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={radioValue}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <FormControlLabel
          value="TAG"
          control={<Radio onChange={handleRadio} />}
          label="Tags"
        />
        {os === "WINDOWS" ? (
          <FormControlLabel
            disabled={disabledOU}
            value="OU"
            control={<Radio onChange={handleRadio} />}
            label="Organization Unit"
          />
        ) : null}

        <FormControlLabel
          disabled={disabledPatterns}
          value="PATTERNS"
          control={<Radio onChange={handleRadio} />}
          label="Device Pattern"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioSelection;
