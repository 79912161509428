import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";

const AzureSetup = ({ tenantName }) => {
  const userData = useUserStore((state) => state.user);
  const [clientId, setClientId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [objectId, setObjectId] = useState("");
  const [errors, setErrors] = useState({});
  const [config, setConfig] = useState(null);

  const validateFields = () => {
    const newErrors = {};
    if (!clientId) newErrors.clientId = "Client ID is required";
    if (!tenantId) newErrors.tenantId = "Tenant ID is required";
    if (!clientSecret) newErrors.clientSecret = "Client Secret is required";
    if (!objectId) newErrors.objectId = "Object ID is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      try {
        const response = await axios.post(`${NGROK}/api/azure/auth/configure`, {
          client_id: clientId,
          az_tenant_id: tenantId,
          client_secret: clientSecret,
          object_id: objectId,
          tenantName,
          ws_tenant_email: userData?.email,
        });
        console.log(response?.data);
      } catch (error) {
        console.error("Error configuring Azure settings:", error);
      }
    }
  };
  const fetchAzureConfig = async () => {
    const response = await axios.get(`${NGROK}/api/azure/auth/configuration`, {
      params: {
        email: userData?.email,
      },
    });
    if (response.data) {
      setConfig(response?.data);
      setClientId(response.data.clientId);
      setTenantId(response.data.tenantId);
      setClientSecret(response.data.clientSecret);
      setObjectId(response.data.objectId);
    }
  };

  useEffect(() => {
    fetchAzureConfig();
  }, [userData.email]);

  return (
    <>
      <Container maxWidth="md">
        {config ? (
          <Alert sx={{ marginTop: 1 }} severity="success">
            {"Azure account configured successfully"}
          </Alert>
        ) : null}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Azure Initial Setup
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Client Id"
                variant="outlined"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
                error={!!errors.clientId}
                helperText={errors.clientId}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Tenant Id"
                variant="outlined"
                value={tenantId}
                onChange={(e) => setTenantId(e.target.value)}
                error={!!errors.tenantId}
                helperText={errors.tenantId}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Client Secret"
                variant="outlined"
                type="password"
                value={clientSecret}
                onChange={(e) => setClientSecret(e.target.value)}
                error={!!errors.clientSecret}
                helperText={errors.clientSecret}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Object Id"
                variant="outlined"
                value={objectId}
                onChange={(e) => setObjectId(e.target.value)}
                error={!!errors.objectId}
                helperText={errors.objectId}
              />
            </Box>
            <Button type="submit" variant="contained" color="secondary">
              Submit Configuration
            </Button>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default AzureSetup;
