import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Stack,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";
import { Delete, Refresh, Add } from "@mui/icons-material";
import axios from "axios";
import { myLocalStorage } from "../../../components/StorageHelper";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";

const TenantAccessManagement = ({ domainName, email }) => {
  const [apiTokens, setApiTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const userData = useUserStore((state) => state.user);

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  useEffect(() => {
    fetchTokens(true);
    const interval = setInterval(() => fetchTokens(), [5000]);
    return () => clearInterval(interval);
  }, [selectedTenantName, userData]);

  const fetchTokens = async (isFirst = false) => {
    try {
      if (isFirst) setLoading(true);
      const response = await axios.get(
        `${NGROK}/api/expose/${selectedTenantName}/getTokens`,
        {
          params: { email: userData.email },
        },
      );
      if (response.data) {
        setApiTokens([response.data]);
      } else {
        setApiTokens([]);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching tokens", error);
      setLoading(false);
    }
  };

  const deleteToken = async (apiKey) => {
    try {
      await axios.delete(
        `${NGROK}/api/expose/${selectedTenantName}/deleteToken`,
        {
          params: { email: userData.email, token: apiKey },
        },
      );
      setApiTokens([]);
    } catch (error) {
      console.error("Error deleting token", error);
    }
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  const handleCreateToken = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/expose/${selectedTenantName}/createToken`,
        { params: { email: userData.email } },
      );
    } catch (error) {
      console.error("Error creating token", error);
    }
  };

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };

    fetchTenants();
  }, [userData]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          position: "relative",
        }}
      >
        {tenantList?.length > 0 && (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={selectedTenantName || " "}
            options={tenantList.map((tenant) => tenant.tenantName)}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Tenant List" />
            )}
            onChange={(e, value) => handleTenantChange(value)}
          />
        )}

        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          disabled={apiTokens.length > 0}
          onClick={handleCreateToken}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          Create Token
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom mt={5}>
        API Access
      </Typography>

      <TableContainer>
        <Table
          aria-label="simple table"
          sx={{
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#233044",
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Tenant Name
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Token
              </TableCell>

              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  Loading...
                </TableCell>
              </TableRow>
            ) : apiTokens.length > 0 ? (
              apiTokens.map((token) => (
                <TableRow key={token.apiKey}>
                  <TableCell align="center">{token.tenantName}</TableCell>
                  <TableCell align="center">{token.apiKey}</TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<Delete />}
                      onClick={() => deleteToken(token.apiKey)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  No tokens available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TenantAccessManagement;
