import React from "react";

import { Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { FaLinux, FaWindows, FaApple } from "react-icons/fa";
import useUserStore from "../../../../services/userStore";

const OperationSystemTab = ({
  windowsContent,
  linuxContent,
  macContent,
  osValue,
  setOsValue,
}) => {
  const userData = useUserStore((state) => state.user);
  let userRole = !userData.userRole ? "" : userData.userRole;
  const handleChange = (event, newValue) => {
    setOsValue(newValue);
    myLocalStorage.setItem("selectedOS", newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={osValue}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          display={"flex"}
          justifyContent={"center"}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="WINDOWS" value={"WINDOWS"} icon={<FaWindows />} />
            {userRole !== "epam-admin" ? (
              <Tab label="LINUX" value={"LINUX"} icon={<FaLinux />} />
            ) : null}
            {userRole != "server-pam-admin" ? (
              <Tab label="MAC" value={"MACOS"} icon={<FaApple />} />
            ) : null}
          </TabList>
        </Box>
        <CustomTabPanel value={osValue} index={"WINDOWS"}>
          {windowsContent}
        </CustomTabPanel>
        <CustomTabPanel value={osValue} index={"LINUX"}>
          {linuxContent}
        </CustomTabPanel>
        <CustomTabPanel value={osValue} index={"MACOS"}>
          {macContent}
        </CustomTabPanel>
      </TabContext>
    </Box>
  );
};

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default OperationSystemTab;
