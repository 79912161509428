import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EC2Icon from "@mui/icons-material/CloudCircle";
import S3Icon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import { myLocalStorage } from "../../../../components/StorageHelper";
import useUserStore from "../../../../services/userStore";
import AccessEc2Resources from "./AccessEc2Resources";
import AccessS3Buckets from "./AccessS3Buckets";
import AccessRDSInstances from "./AccessRDSInstances";
import { awsIcons } from "../AWSIcons";

const awsResources = [
  {
    id: "EC2",
    name: "EC2 Instances",
    icon: awsIcons.instances,
    description: "Virtual servers in the cloud for running applications.",
  },
  {
    id: "S3",
    name: "S3 Buckets",
    icon: awsIcons.buckets,
    description: "Scalable storage service for storing and retrieving data.",
  },
  {
    id: "RDS",
    name: "RDS",
    icon: awsIcons.rds,
    description:
      "Amazon RDS (Relational Database Service) is a managed database service offered by Amazon Web Services",
  },
  /* {
    id: "Lambda",
    name: "Lambda Functions",
    icon: <LambdaIcon />,
    description:
      "Serverless compute service that runs code in response to events.",
  },
  {
    id: "IAMUsers",
    name: "IAM Users",
    icon: <PersonIcon />,
    description:
      "AWS identities with specific permissions to manage resources.",
  },
  {
    id: "IAMRoles",
    name: "IAM Roles",
    icon: <IAMIcon />,
    description: "Roles that grant specific permissions to AWS services.",
  },
  {
    id: "Security",
    name: "Security Groups",
    icon: <SGIcon />,
    description:
      "Virtual firewalls for controlling inbound and outbound traffic.",
  }, */
];

const AccessAWSResources = ({ user, groups }) => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedResource, setSelectedResource] = useState(null);
  const [publishedEc2Instances, setPublishedEc2Instances] = useState([]);
  const [publishedS3Buckets, setPublishedS3Buckets] = useState([]);
  const [publishedRDSInstances, setPublishedRDSInstances] = useState([]);

  const tenantName = myLocalStorage.getItem("latestTenant")?.tenantName;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredAwsResources = awsResources.filter((resource) =>
    resource.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const fetchPublishedRDSInstances = async (isFirst = false) => {
    if (isFirst) setLoading(true);
    const response = await axios.get(
      `${NGROK}/api/aws/allpublishRDSInstances?tenantName=${tenantName}`,
    );
    setPublishedRDSInstances(response?.data);
    setLoading(false);
  };

  const fetchPublishedS3Buckets = async (isFirst = false) => {
    if (isFirst) setLoading(true);
    const response = await axios.get(
      `${NGROK}/api/aws/getAllS3PublishBuckets?tenantName=${tenantName}`,
    );
    setPublishedS3Buckets(response?.data);
    setLoading(false);
  };

  const fetchPublishedEc2Instances = async (isFirst = false) => {
    if (isFirst) setLoading(true);
    const response = await axios.get(
      `${NGROK}/api/aws/getPublishedEC2Instances?tenantName=${tenantName}`,
    );
    setPublishedEc2Instances(response?.data);
    setLoading(false);
  };
  const fetchResourceDetails = async (resource) => {
    setSelectedResource(resource);
    switch (resource?.id) {
      case "EC2":
        fetchPublishedEc2Instances(true);
        break;
      case "S3":
        fetchPublishedS3Buckets(true);
      case "RDS":
        fetchPublishedRDSInstances(true);
    }
  };

  useEffect(() => {
    if (awsResources.length > 0) {
      setSelectedResource(awsResources[0]);
    }
  }, []);

  useEffect(() => {
    fetchResourceDetails(selectedResource);
  }, [tenantName]);

  return (
    <>
      <Stack direction={"row"} spacing={5}>
        <Box>
          <TableContainer component={Paper}>
            <Table
              sx={{
                height: "fit-content",
                "& th": {
                  background: "#233044",
                  color: "#fff",
                },
                "& td, & th": {
                  border: "1px solid #233044",
                  fontSize: "18px",
                },
              }}
              size="large"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {awsIcons.resources}
                      <Box ml={2}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "18px",
                          }}
                        >
                          Resource Details
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={1}>
                    <TextField
                      variant="outlined"
                      placeholder="Search Resources"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                {filteredAwsResources.map((resource) => (
                  <TableRow
                    key={resource.name}
                    selected={resource === selectedResource}
                    onClick={() => fetchResourceDetails(resource)}
                    sx={{
                      "&:hover": {
                        background: "#f5f5f5",
                        cursor: "pointer",
                      },
                      "& td, & th": {
                        border: "1px solid #233044",
                        fontSize: "18px",
                      },
                      "&.Mui-selected": {
                        background: "#233044 !important",
                        "& td, & th": {
                          color: "#fff",
                        },
                      },
                    }}
                  >
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {resource.icon}
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {resource.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              maxWidth: "350px",
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {resource.description}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {selectedResource ? (
          <Box>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {selectedResource?.id === "EC2" ? (
                  <AccessEc2Resources
                    publishedEc2Instances={publishedEc2Instances}
                    setPublishedEc2Instances={setPublishedEc2Instances}
                    user={user}
                  />
                ) : null}
                {selectedResource?.id === "S3" ? (
                  <AccessS3Buckets
                    publishedS3Buckets={publishedS3Buckets}
                    setPublishedS3Buckets={setPublishedS3Buckets}
                    user={user}
                  />
                ) : null}
                {selectedResource?.id === "RDS" ? (
                  <AccessRDSInstances
                    publishedRDSInstances={publishedRDSInstances}
                    setPublishedRDSInstances={setPublishedRDSInstances}
                    user={user}
                  />
                ) : null}
              </>
            )}
          </Box>
        ) : null}
      </Stack>
    </>
  );
};

export default AccessAWSResources;
