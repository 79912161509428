import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import { getResourceNameField } from "../../../services/Helpers";

const ResourcesTable = ({
  resourceType,
  os,
  selectedCategory,
  allSelectedResources,
  fetchResources,
  selectedTenantName,
}) => {
  const [resourceSearchValues] = useState("");
  const resourceNameField = getResourceNameField(resourceType);
  const filteredResources = allSelectedResources?.filter((resource) => {
    return resource.categories?.some((category) => {
      const categoryName = category.categoryName?.trim().toLowerCase();
      const selectedCategoryName = selectedCategory?.name?.trim().toLowerCase();

      const isMatch = categoryName === selectedCategoryName;

      return isMatch;
    });
  });

  const selectedResources = filteredResources?.length
    ? filteredResources.filter((resource) => {
        const resourceName =
          resourceType === "App" || resourceType === "Folder"
            ? "path"
            : resourceType === "Group"
              ? "cn"
              : resourceType === "Publisher"
                ? "friendlyName"
                : "name";
        return (
          resourceSearchValues === "" ||
          resource[resourceName]
            .toLowerCase()
            .includes(resourceSearchValues.toLowerCase())
        );
      })
    : [];

  useEffect(() => {
    fetchResources(os);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [os, selectedCategory, resourceType, selectedTenantName]);

  return (
    <Stack spacing={2} marginTop={"-5.5px"}>
      <Typography variant="h6" color="initial" fontWeight={600} align="center">
        Total Count: {selectedResources?.length || "0"}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          minWidth: 450,
          maxWidth: 1200,
          height: "fit-content",
        }}
      >
        <Table
          aria-label="simple table"
          sx={{
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead sx={{ backgroundColor: "#233044" }}>
            <TableRow>
              <TableCell sx={{ color: "white", width: 60 }} align="center">
                <Typography fontSize={17} fontWeight={600} width={60}>
                  Sl No
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography fontSize={17} fontWeight={600}>
                  {resourceType}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedResources?.length
              ? selectedResources.map((resource, i) => (
                  <TableRow key={resource.id}>
                    <TableCell component="td" scope="row" align="center">
                      {i + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: 540,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        variant="body1"
                        maxWidth={300}
                        fontSize={16}
                        sx={{ wordWrap: "break-word", marginRight: 10 }}
                      >
                        {resource[resourceNameField]}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ResourcesTable;
