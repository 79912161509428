import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { awsIcons } from "../AWSIcons";

const RDSInstances = ({ rdsDatas, setRdsDatas, tenantName }) => {
  const [selectedResourceInstance, setSelectedResourceInstance] =
    useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const selectResourceAndDetails = (instanceId) => {
    setSelectedResourceInstance(instanceId);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const fetchRDSInstances = async (isFirst = false) => {
    if (isFirst) {
      setLoading(true);
    }
    if (tenantName) {
      const response = await axios.get(
        `${NGROK}/api/aws/getAllRDSInstances?tenantName=${tenantName}`,
      );
      setRdsDatas(response?.data);
    }
    setLoading(false);
  };
  const updateRDSManage = async (rdsDBInstanceArn) => {
    if (tenantName) {
      const response = await axios.put(
        `${NGROK}/api/aws/publishRDSInstances?tenantName=${tenantName}&arn=${rdsDBInstanceArn}`,
      );
      fetchRDSInstances();
    }
  };

  const getIcons = (engine) => {
    const engineLower = engine.toLowerCase();

    switch (true) {
      case engineLower.includes("mysql"):
        return awsIcons.mysql;
      case engineLower.includes("sql"):
        return awsIcons.sqlServer;
      case engineLower.includes("postgres"):
        return awsIcons.psql;
      case engineLower.includes("oracle"):
        return awsIcons.oracle;
      case engineLower.includes("maria"):
        return awsIcons.maria;
      default:
        return awsIcons.oracle;
    }
  };

  const filteredrdsDatas = rdsDatas?.filter((rdsData) =>
    rdsData.rdsIdentifierName.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const filteredData = rdsDatas.filter(
    (rdsData) => rdsData.rdsDBInstanceArn === selectedResourceInstance,
  );

  useEffect(() => {
    fetchRDSInstances(true);
    const interval = setInterval(fetchRDSInstances, 5000);
    return () => clearInterval(interval);
  }, [tenantName]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Stack direction={"row"} spacing={5}>
        <Box width={"40%"}>
          <TableContainer component={Paper}>
            <Table
              sx={{
                height: "fit-content",
                "& th": {
                  background: "#233044",
                  color: "#fff",
                },
                "& td, & th": {
                  border: "1px solid #233044",
                  fontSize: "18px",
                },
              }}
              size="large"
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7} align="left">
                    RDS Instances
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : rdsDatas.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={7}>
                        <TextField
                          variant="outlined"
                          placeholder="Search Instance"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>

                    {filteredrdsDatas.map((rdsData, index) => (
                      <TableRow
                        key={index}
                        selected={
                          rdsData.rdsDBInstanceArn === selectedResourceInstance
                        }
                        onClick={() => {
                          selectResourceAndDetails(rdsData.rdsDBInstanceArn);
                        }}
                        sx={{
                          "&:hover": {
                            background: "#f5f5f5",
                            cursor: "pointer",
                          },
                          "&.Mui-selected": {
                            background: "#233044 !important",
                            "& td, & th": {
                              color: "#fff",
                            },
                          },
                          "& td, & th": {
                            border: "1px solid #233044",
                            fontSize: "18px",
                          },
                        }}
                      >
                        <TableCell>
                          <Stack
                            direction={"row"}
                            spacing={5}
                            display="flex"
                            alignItems="center"
                          >
                            <div>{getIcons(rdsData.rdsEngine)}</div>

                            <Box ml={2}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: 18,
                                }}
                              >
                                {rdsData.rdsIdentifierName}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: 14,
                                }}
                              >
                                Region : {rdsData.rdsAvailabilityZone}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Switch
                              checked={rdsData.isManagedRDS}
                              onChange={() =>
                                updateRDSManage(rdsData.rdsDBInstanceArn)
                              }
                            />
                            <Typography variant="body2">Publish</Typography>{" "}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography align="center">
                        No RDS Instance Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {selectedResourceInstance && filteredData ? (
          <Box
            width="50%"
            sx={{
              border: "1px solid",
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background: "#233044",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
                fontSize: "18px",
                p: 4.8,
              }}
            >
              Details of {filteredData[0].rdsIdentifierName}
            </Typography>
            <List>
              {filteredData.length > 0 ? (
                filteredData.map((rdsData, index) => (
                  <div key={index}>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Identifier Name</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {rdsData.rdsIdentifierName}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Database Engine</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {rdsData.rdsEngine}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Availablity</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {rdsData.rdsAvailabilityZone}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>UserName</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {rdsData.rdsInstanceMasterUserName}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Created Time</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {rdsData.rdsInstanceCreatedTime}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Db Instance Arn</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {rdsData.rdsDBInstanceArn}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </div>
                ))
              ) : (
                <Typography variant="body1"> No details available</Typography>
              )}
            </List>
          </Box>
        ) : null}
      </Stack>
    </>
  );
};

export default RDSInstances;
