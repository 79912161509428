import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";

import "../pages.scss";
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RadioAuthFilter from "../../components/RadioAuthFilter";
import MyHeaderTableCell from "../Components/MyHeaderTableCell";
import {
  getFilteredProfileResources,
  getInheritedResourceStatusValue,
  getResourceSelectValue,
} from "../../../services/Helpers";
import useDebouncedValue from "../../../hooks/useDebouncedValue";

const ProfileDataAcces = ({
  userData,
  showCount,
  setShowCount,
  foldersSearchValues,
  setFoldersSearchValues,
  tenantName,
  selectedProfile,
}) => {
  const tableRef = useRef();

  const [filterFoldersValue, setFilterFoldersValue] = useState("ALL");
  const [folders, setFolders] = useState([]);

  const debouncedSearchTerm = useDebouncedValue(foldersSearchValues, 1000);

  const findFolder = (value) => {
    setFoldersSearchValues(value);
  };

  const changeFolderStatus = async (folder, groupStatus) => {
    const profileResource =
      groupStatus === "ENABLED"
        ? "ALLOWED"
        : groupStatus === "DISABLED"
          ? "BLOCKED"
          : groupStatus === "DYNAMIC"
            ? "DYNAMIC"
            : "INHERITED";
    const updatedFolders = folders.map((el) =>
      el.id === folder.id ? { ...el, profileResource } : el,
    );

    setFolders(updatedFolders);

    try {
      await axios.put(`${NGROK}/api/profiles/folders/update`, {
        email: userData.email,
        folderId: folder.folderId,
        groupStatus,
        profileId: folder.profileId,
        tenantName,
      });
    } catch (error) {
      setFolders(folders);
      console.error("Failed to update folder status:", error);
    }
  };

  const handleFilterResourceValue = (e) => {
    setFilterFoldersValue(e.target.value);
  };

  useEffect(() => {
    const getFolders = async (profile) => {
      setFoldersSearchValues("");
      setFolders(undefined);

      const response = await axios.get(
        `${NGROK}/api/${profile.os}/profiles/${profile.id}/folders`,
      );
      if (response.data) {
        const foldersSortedById = response.data.content.sort(
          (a, b) => a.folderId - b.folderId,
        );
        setFolders(foldersSortedById);
      }
    };
    getFolders(selectedProfile);
  }, []);

  const filteredFolders = getFilteredProfileResources(
    debouncedSearchTerm,
    filterFoldersValue,
    folders,
  );

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const table = tableRef.current;
        const rows = table.querySelectorAll("tr");

        if (rows.length >= showCount) {
          const fiftiethRow = rows[showCount - 1];
          const rect = fiftiethRow.getBoundingClientRect();
          const tableRect = table.getBoundingClientRect();
          if (
            rect.top >= tableRect.top &&
            rect.bottom <= tableRect.bottom &&
            filteredFolders?.length > showCount
          ) {
            setShowCount((prevCount) => prevCount + 50);
          }
        }
      }
    };

    const tableContainer = tableRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tableRef, folders, showCount, setShowCount]);

  return (
    <>
      {folders && folders.length ? (
        <Stack spacing={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            sx={{ padding: "15px" }}
          >
            <RadioAuthFilter
              filterResourceValue={filterFoldersValue}
              filterName={"Filter by privilege"}
              handleFilterResourceValue={handleFilterResourceValue}
            />
            <TextField
              sx={{ width: 250 }}
              label="Search..."
              id="searchForGroup"
              value={foldersSearchValues}
              onChange={(e) => findFolder(e.target.value)}
            />
          </Box>
          <Typography
            variant="h6"
            color="initial"
            fontWeight={600}
            align="center"
          >
            Total Count: {filteredFolders?.length || "0"}
          </Typography>

          <TableContainer
            component={Paper}
            ref={tableRef}
            sx={{
              height: "fit-content !important",
              maxHeight: "100vh !important",
              overflowY: "auto !important",
            }}
          >
            <Table
              sx={{
                width: "100%",
                height: "fit-content",
                "& td, & th": {
                  border: "1px solid #233044",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Folder
                  </MyHeaderTableCell>
                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Trust level
                  </MyHeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredFolders?.slice(0, showCount).map((folder) => (
                  <TableRow
                    className="groupRow"
                    key={`${folder.id}${folder.folderName}${folder.folderId}`}
                  >
                    <TableCell
                      component="td"
                      scope="row"
                      className="groupRowName userName"
                      title={folder.path}
                    >
                      <Stack direction={"row"} spacing={1.5}>
                        <FolderOutlinedIcon />
                        <span>{folder.path}</span>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Box className="profilePrivilegeLevelTableCell">
                        <Select
                          sx={{ minWidth: "100px" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={getResourceSelectValue(folder)}
                          size="small"
                          onChange={(event) =>
                            changeFolderStatus(folder, event.target.value)
                          }
                        >
                          <MenuItem value={"ENABLED"}>ALLOWED</MenuItem>
                          <MenuItem value={"DISABLED"}>DENIED</MenuItem>
                          <MenuItem value={"DYNAMIC"}>TIMED</MenuItem>
                          {folder.trustLevel ? (
                            <MenuItem value={"INHERITED"}>
                              INHERITED{" "}
                              {getInheritedResourceStatusValue(folder)}
                            </MenuItem>
                          ) : null}
                        </Select>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
                {filteredFolders?.length > showCount ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      ) : folders === undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box pl={5}>
          <p style={{ fontSize: "20px", fontWeight: "500" }}>
            There are no any folders.
          </p>
        </Box>
      )}
    </>
  );
};

export default ProfileDataAcces;
