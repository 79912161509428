import { List, ListItem, ListItemText, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

const AzureGroupDetails = ({ group }) => {
  return (
    <>
      <List>
        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Group Name:</strong> {group?.displayName || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Mail:</strong> {group?.mail || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Mail Nick Name:</strong> {group?.mailNickname || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Mail Enabled:</strong>{" "}
                {group?.mailEnabled ? "Yes" : "No"}
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Visibility:</strong> {group?.visibility || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Description:</strong> {group?.description || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Security Enabled:</strong>{" "}
                {group?.securityEnabled ? "Yes" : "No"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Security Identifier:</strong>{" "}
                {group?.securityIdentifier || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Created On:</strong>{" "}
                {moment(group?.azureCreatedDateTime).format(
                  "DD/MM/YYYY HH:MM:ss",
                )}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </>
  );
};

export default AzureGroupDetails;
