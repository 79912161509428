import axios from "axios";
import { NGROK } from "../../../APIs";

export const getPublishers = async (selectedProfile) => {
  const response = await axios.get(
    `${NGROK}/api/${selectedProfile.os}/profiles/${selectedProfile.id}/publishers`,
  );
  if (response.data) {
    return response.data.content;
  }
};

export const getPublishersById = async (latestTenant, latestComputerId) => {
  const response = await axios.get(
    `${NGROK}/api/${latestTenant}/computers/${latestComputerId}/publishers`,
  );
  return response.data.content;
};

export const createCategory = async (
  newCategory,
  categoryPriority,
  searchPatternList,
  os,
  resourceType,
  email,
  manualExclusions,
  tenantName,
) => {
  const newOs = os === "mac" ? "macos" : os;

  try {
    const response = await axios.post(`${NGROK}/api/category`, {
      name: newCategory,
      reputationScore: categoryPriority,
      searchPatterns: searchPatternList,
      os: newOs,
      resourceType: resourceType.toUpperCase(),
      profiles: [],
      userEmail: email,
      manualExclusions,
      tenantName,
    });
    return response.data;
  } catch (error) {
    console.error(error, "error during updating categories");
  }
};

//general API
export const fetchTenantsData = async (email, setter) => {
  try {
    const response = await axios.get(`${NGROK}/api/get-all-domains`);
    setter(response?.data);
  } catch (error) {
    console.error(error);
    setter([]);
  }
};

export const getUsers = async (tenantName, computerId, role, email) => {
  if (role === "TENANT_ADMIN" || role === "SYS_ADMIN") {
    const response = await axios.get(
      `${NGROK}/api/${tenantName}/computer-users/${computerId}`,
    );
    return response.data?.content;
  } else if (role === "TENANT_USER") {
    const response = await axios.get(
      `${NGROK}/api/${tenantName}/computer-users/${computerId}/users?email=${email}`,
    );
    return response.data;
  }
};
