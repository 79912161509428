import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { NGROK } from "../../../../APIs";
import axios from "axios";
import { myLocalStorage } from "../../../../components/StorageHelper";
import * as XLSX from "xlsx";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const WhiteListHashesDetails = () => {
  const [hash, setHash] = useState("");
  const [hashList, setHashList] = useState([]);
  const [error, setError] = useState(null);
  const [label, setLabel] = useState("");
  const [enableDialog, setEnableDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState(null);
  const [dragError, setDragError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedHashes, setSelectedHashes] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const location = useLocation();
  const [showAllHashes, setShowAllHashes] = useState(false);

  const sha256Regex = /^[a-fA-F0-9]{64}$/;
  const handleAddHash = () => {
    if (!sha256Regex.test(hash?.toLowerCase())) {
      setError(
        "Invalid SHA-256 hash. Please enter a valid 64-character hexadecimal string.",
      );
      return;
    }
    setError(null);

    if (hash?.toLowerCase() && !hashList.includes(hash?.toLowerCase())) {
      setHashList([
        ...hashList,
        { hash: hash?.toLowerCase(), label: label || "" },
      ]);
    }
    setHash("");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      readFile(file);
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    setDragError(null);

    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      readFile(file);
    }
  };

  const readFile = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const hashes = [];
      const invalidHashes = [];

      jsonData.forEach((row, index) => {
        if (index > 0) {
          const [hash, label] = row;

          if (sha256Regex.test(hash?.toLowerCase())) {
            hashes.push({
              hash: hash?.toLowerCase(),
              label: label || "",
            });
          } else {
            invalidHashes.push(hash?.toLowerCase());
          }
        }
      });

      if (invalidHashes.length > 0) {
        setDragError(`Invalid hashes found: ${invalidHashes.join(", ")}`);
      } else {
        setDragError(null);
      }

      setHashList([...hashList, ...hashes]);
    };

    reader.onerror = () => {
      setDragError("Error reading the file");
    };

    reader.readAsArrayBuffer(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const enableWhitelistCall = async (value) => {
    await axios.put(
      `${NGROK}/api/enableWhitelistingforpolicyid/${profile?.id}?isenabled=${value}`,
    );
  };

  const handleSubmit = async () => {
    const whitelistHashes = Array.isArray(hashList)
      ? hashList
      : Array.from(hashList);
    await axios.post(`${NGROK}/api/add-whitelistdetails`, {
      profileId: profile?.id,
      isWhitelistEnabled: true,
      whitelistHashes: whitelistHashes,
    });
    setOpenDialog(false);
  };

  const handleSelectHash = (hash) => {
    if (selectedHashes.includes(hash?.toLowerCase())) {
      setSelectedHashes(selectedHashes.filter((h) => h !== hash));
    } else {
      setSelectedHashes([...selectedHashes, hash]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedHashes(filteredHashes.map((hash) => hash.hashValue));
    } else {
      setSelectedHashes([]);
    }
  };
  const handleDeleteSelectedHashes = async () => {
    let selectedHashesList = selectedHashes;
    const updatedList = hashList.filter(
      (hash) => !selectedHashes.includes(hash),
    );
    setHashList(updatedList);

    const response = await axios.put(
      `${NGROK}/api/deleteHashesByPolicyId/${profile?.id}`,
      {
        hashList: selectedHashesList,
      },
    );
    const resp = Array.isArray(response?.data)
      ? response?.data
      : [response?.data];
    setData(resp[0]);
    setSelectedHashes([]);
  };
  const handleDeleteHash = (hashToDelete) => {
    setHashList(hashList.filter((h) => h.hash !== hashToDelete));
    setSelectedHashes(selectedHashes.filter((h) => h.hash !== hashToDelete));
  };

  const handleOpenDialog = () => {
    setHashList([]);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => setOpenDialog(false);
  const handleTabChange = (event, newValue) => setTabIndex(newValue);
  const getAllProfileHashes = async (isFirst = false) => {
    const response = await axios.get(
      `${NGROK}/api/getwhitelistdetailsByPolicyId/${profile?.id}`,
    );
    const resp = Array.isArray(response?.data)
      ? response?.data
      : [response?.data];
    setData(resp[0]);
    if (resp[0] && isFirst) {
      setEnableDialog(
        resp[0]?.whitelistEnabled ? resp[0]?.whitelistEnabled : false,
      );
    }
  };

  const locationState = location?.state;
  const profile =
    locationState?.defaultProfile || myLocalStorage.getItem("selectedProfile");

  const filteredHashes = data?.whitelistHashes?.filter((hash) =>
    hash.hashValue.toLowerCase().includes(searchInput.toLowerCase()),
  );

  const handleToggleShowAll = () => {
    setShowAllHashes((prev) => !prev);
  };

  useEffect(() => {
    getAllProfileHashes(true);
    const interval = setInterval(() => {
      getAllProfileHashes();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Box>
        <Stack
          direction={"row"}
          spacing={5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Switch
              checked={enableDialog}
              onChange={(e) => {
                setEnableDialog(e.target.checked);
                enableWhitelistCall(e.target.checked);
              }}
              sx={{
                alignSelf: "center",
                "& .MuiSvgIcon-root": {
                  fontSize: 28,
                },
              }}
            />
            <Typography
              sx={{
                fontSize: 16,
              }}
            >
              Enable
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDialog}
            disabled={!enableDialog}
            sx={{ padding: 2, fontSize: "16px" }}
          >
            Add Hash
          </Button>
          {selectedHashes.length > 0 && (
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteSelectedHashes}
              sx={{ marginTop: 2, padding: 2, fontSize: "16px" }}
            >
              Delete
            </Button>
          )}
        </Stack>

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Update Hashes</DialogTitle>
          <DialogContent>
            <Tabs value={tabIndex} onChange={handleTabChange} centered>
              <Tab label="Update Manually" />
              <Tab label="File Upload" />
            </Tabs>

            {tabIndex === 0 && (
              <Stack spacing={2} sx={{ marginY: 2 }}>
                <TextField
                  label="Hash label (Optional)"
                  variant="outlined"
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                  fullWidth
                  placeholder="Hash label (Optional)"
                />
                <TextField
                  label="Enter SHA-256 Hash"
                  variant="outlined"
                  value={hash}
                  onChange={(e) => setHash(e.target.value)}
                  fullWidth
                  error={!!error}
                  placeholder="Enter SHA-256 hash here"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddHash}
                  fullWidth
                >
                  Add Hash
                </Button>
                {error && <Alert severity="error">{error}</Alert>}
              </Stack>
            )}

            {tabIndex === 1 && (
              <Stack spacing={2} sx={{ marginY: 2 }}>
                <Box
                  onDrop={handleFileDrop}
                  onDragOver={handleDragOver}
                  sx={{
                    border: "2px dashed gray",
                    borderRadius: 2,
                    padding: 2,
                    textAlign: "center",
                    marginY: 2,
                  }}
                >
                  <Typography variant="body1">
                    Drag and drop a Excel file with SHA-256 hashes here
                  </Typography>
                  <Typography variant="body1">Or</Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => fileInputRef.current.click()}
                  >
                    Select File
                  </Button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept=".xlsx,.xls"
                    style={{ display: "none" }}
                  />
                </Box>
                {dragError && <Alert severity="warning">{dragError}</Alert>}
                {selectedFile && (
                  <Typography variant="subtitle1" color="textSecondary">
                    <strong>Selected file:</strong> {selectedFile.name}
                  </Typography>
                )}
              </Stack>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                maxHeight: showAllHashes ? 200 : "auto",
                overflowY: "auto",
              }}
            >
              {(showAllHashes ? hashList : hashList.slice(0, 5)).map(
                (h, index) => (
                  <Chip
                    key={index}
                    label={h.hash}
                    onDelete={() => handleDeleteHash(h.hash)}
                    color="primary"
                    sx={{ marginBottom: 1 }}
                  />
                ),
              )}
              {hashList.length > 5 && (
                <>
                  <Typography
                    variant="body2"
                    color="primary"
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      textAlign: "left",
                      marginBottom: 0.5,
                    }}
                    onClick={handleToggleShowAll}
                  >
                    {showAllHashes
                      ? "show less"
                      : `show more +${hashList.length - 5}`}
                  </Typography>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={handleCloseDialog} color="secondary">
                Close
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
                disabled={hashList.length === 0}
              >
                Submit Hashes
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <Box sx={{ mt: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 5,
            }}
          >
            <Typography variant="h5" component="div">
              <strong>SHA-256 Whitelisting Hashes</strong>
              <Stack direction={"column"} spacing={2} display={"flex"} mt={2}>
                <Typography variant="body1">
                  Whitelisting controls the applications users can download and
                  install.
                </Typography>
                <Typography variant="body1">
                  Only installers in whitelist hashes will be allowed to be
                  installed by the user.
                </Typography>
              </Stack>
            </Typography>
            <TextField
              label="Search Hash"
              variant="outlined"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              sx={{ mt: 2, width: "500px" }}
            />
          </Box>
          {data && enableDialog ? (
            <TableContainer component={Paper}>
              <Table
                sx={{
                  height: "fit-content",
                  "& th": {
                    background: "#233044",
                    color: "#fff",
                  },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "18px",
                  },
                }}
                size="large"
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selectedHashes.length > 0 &&
                          selectedHashes.length < filteredHashes.length
                        }
                        checked={
                          selectedHashes.length === filteredHashes.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell align="left">Hash value</TableCell>
                    <TableCell align="left">Hash type</TableCell>
                    <TableCell align="left">Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredHashes?.length > 0 ? (
                    filteredHashes?.map((hash, index) => (
                      <TableRow key={index}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedHashes.includes(hash.hashValue)}
                            onChange={() => handleSelectHash(hash.hashValue)}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: 16,
                            }}
                          >
                            {hash.hashValue}
                          </Typography>
                          {hash?.whitelistLabel ? (
                            <Typography variant="body1" color="textSecondary">
                              <strong>Label:</strong> {hash?.whitelistLabel}
                            </Typography>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              fontSize: 16,
                            }}
                          >
                            SHA-256
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              fontSize: 16,
                            }}
                          >
                            {new Date(hash.createdAt).toLocaleString()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        <Typography variant="body1">
                          No data available
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1">No data available</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default WhiteListHashesDetails;
