import axios from "axios";
import { NGROK } from "../../../APIs";

export const fetchDevices = async (tenantName, os) => {
  try {
    const response = await axios.get(
      `${NGROK}/api/endpoints-category/computers?tenantName=${tenantName}&os=${os}`,
    );

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchDeviceGroups = async (tenantName, os) => {
  try {
    const response = await axios.get(
      `${NGROK}/api/endpoints-category?tenantName=${tenantName}&os=${os}`,
    );

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createNewDeviceGroup = async (
  name,
  tenantName,
  os,
  profileId,
  profileName,
  priority,
  searchPatterns,
  manualExclusions,
  tags,
) => {
  try {
    const response = await axios.post(`${NGROK}/api/endpoints-category`, {
      name,
      tenantName,
      profileId,
      profileName,
      os,
      reputationScore: priority,
      searchPatterns,
      manualExclusions,
      installTags: tags,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createNewDeviceGroupAsOu = async (
  name,
  tenantName,
  os,
  profileId,
  profileName,
  priority,
  ouId,
  endpointIdList,
  tags,
) => {
  try {
    const response = await axios.post(`${NGROK}/api/endpoints-category`, {
      name,
      tenantName,
      profileId,
      profileName,
      os,
      reputationScore: priority,
      ouId,
      endpointIdList,
      installTags: tags,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const editDeviceGroup = async (
  uuid,
  name,
  newName,
  tenantName,
  os,
  profileId,
  profileName,
  priority,
  searchPatterns,
  newSearchPatterns,
  manualExclusions,
  tags,
) => {
  try {
    const response = await axios.put(`${NGROK}/api/endpoints-category`, {
      uuid,
      name,
      newName,
      tenantName,
      profileId,
      profileName,
      os,
      reputationScore: priority,
      oldSearchPatterns: searchPatterns,
      newSearchPatterns,
      manualExclusions,
      installTags: tags,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const editOuGroup = async (
  uuid,
  name,
  newName,
  tenantName,
  os,
  profileId,
  profileName,
  priority,
  ouId,
  endpointIdList,
  tags,
) => {
  try {
    const response = await axios.put(`${NGROK}/api/endpoints-category`, {
      uuid,
      name,
      newName,
      tenantName,
      profileId,
      profileName,
      os,
      reputationScore: priority,
      ouId,
      endpointIdList,
      installTags: tags,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const deleteDeviceGroup = async (uuid) => {
  try {
    const response = await axios.delete(
      `${NGROK}/api/endpoints-category/${uuid}`,
    );

    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};
