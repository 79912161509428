import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  List,
  ListItem,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Stack,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AccessAWSGroups from "./components/AccessAWSGroups";
import useUserStore from "../../../services/userStore";
import AccessAWSPolicies from "./components/AccessAWSPolicies";
import AccessAWSResources from "./components/AccessAWSResources";
import { awsIcons } from "./AWSIcons";

const AWSUserDetails = () => {
  const location = useLocation();
  const { user } = location.state || { user: {} };
  const [selectedAccess, setSelectedAccess] = useState("resources");

  const {
    userName,
    arn,
    creationDate,
    groups = [],
    roles = [],
    attachedUserPolicies = [],
    inlineUserPolicies = [],
    groupPolicies = [],
    groupInlinePolicies = [],
  } = user;

  const getDisplayItems = (items) => items.slice(0, 2);
  const getMoreCount = (items) => items.length - 2;

  const getAllDetails = (val) => {
    setSelectedAccess(val);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Stack direction={"column"} spacing={5}>
        <Paper elevation={4} sx={{ padding: 3, borderRadius: 2 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", marginBottom: 2, fontSize: "18px" }}
          >
            {userName}
            <IconButton size="small" sx={{ marginLeft: 1 }}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "600", fontSize: "18px" }}
              >
                ARN:
              </Typography>
              <Typography variant="body1">{arn}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "600", fontSize: "18px" }}
              >
                Created Date:
              </Typography>
              <Typography variant="body1">
                {new Date(creationDate).toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "600", fontSize: "18px" }}
              >
                Groups:
              </Typography>
              {getDisplayItems(groups).map((group) => (
                <Typography key={group.groupId} variant="body1">
                  {group.groupName}
                </Typography>
              ))}
              {getMoreCount(groups) > 0 && (
                <Tooltip
                  title={
                    <List>
                      {groups.map((group) => (
                        <ListItem key={group.groupId}>
                          {group.groupName}
                        </ListItem>
                      ))}
                    </List>
                  }
                  placement="top"
                >
                  <Typography variant="body1" color="primary">
                    +{getMoreCount(groups)} more
                  </Typography>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "600", fontSize: "18px" }}
              >
                Roles:
              </Typography>
              {getDisplayItems(roles).map((role) => (
                <Typography key={role.roleId} variant="body1">
                  {role.roleName}
                </Typography>
              ))}
              {getMoreCount(roles) > 0 && (
                <Tooltip
                  title={
                    <List>
                      {roles.map((role) => (
                        <ListItem key={role.roleId}>{role.roleName}</ListItem>
                      ))}
                    </List>
                  }
                  placement="top"
                >
                  <Typography variant="body1" color="primary">
                    +{getMoreCount(roles)} more
                  </Typography>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "600", fontSize: "18px" }}
              >
                Policies:
              </Typography>
              {getDisplayItems(attachedUserPolicies).map((policy, index) => (
                <Typography key={index} variant="body1">
                  {policy}
                </Typography>
              ))}
              {getMoreCount(attachedUserPolicies) > 0 && (
                <Tooltip
                  title={
                    <List>
                      {attachedUserPolicies.map((policy, index) => (
                        <ListItem key={index}>{policy}</ListItem>
                      ))}
                    </List>
                  }
                  placement="top"
                >
                  <Typography variant="body1" color="primary">
                    +{getMoreCount(attachedUserPolicies)} more
                  </Typography>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Stack direction={"row"} spacing={2}>
          <Box width={"30%"}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  height: "fit-content",
                  "& th": {
                    background: "#233044",
                    color: "#fff",
                  },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "18px",
                  },
                }}
                size="large"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Privileges</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    <TableRow
                      key={"resources"}
                      selected={selectedAccess === "resources"}
                      onClick={() => {
                        getAllDetails("resources");
                      }}
                      sx={{
                        "&:hover": {
                          background: "#f5f5f5",
                          cursor: "pointer",
                        },
                        "&.Mui-selected": {
                          background: "#233044 !important",
                          "& td, & th": {
                            color: "#fff",
                          },
                        },
                        "& td, & th": {
                          border: "1px solid #233044",
                          fontSize: "18px",
                        },
                      }}
                    >
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          {awsIcons.resources}
                          <Box ml={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: "18px",
                              }}
                            >
                              Resources
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      selected={selectedAccess === "groups"}
                      key={"groups"}
                      onClick={() => {
                        getAllDetails("groups");
                      }}
                      sx={{
                        "&:hover": {
                          background: "#f5f5f5",
                          cursor: "pointer",
                        },
                        "&.Mui-selected": {
                          background: "#233044 !important",
                          "& td, & th": {
                            color: "#fff",
                          },
                        },
                        "& td, & th": {
                          border: "1px solid #233044",
                          fontSize: "18px",
                        },
                      }}
                    >
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          {awsIcons.groups}
                          <Box ml={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: "18px",
                              }}
                            >
                              Groups
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>

                    {/*<TableRow
                      key={"policies"}
                      selected={selectedAccess === "policies"}
                      onClick={() => {
                        getAllDetails("policies");
                      }}
                      sx={{
                        "&:hover": {
                          background: "#f5f5f5",
                          cursor: "pointer",
                        },
                        "&.Mui-selected": {
                          background: "#233044 !important",
                          "& td, & th": {
                            color: "#fff",
                          },
                        },
                        "& td, & th": {
                          border: "1px solid #233044",
                          fontSize: "16px",
                        },
                      }}
                    >
                      <TableCell>Policies/Permissions</TableCell>
                    </TableRow>*/}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {selectedAccess === "groups" ? (
            <AccessAWSGroups user={user} groups={groups} />
          ) : null}
          {selectedAccess === "policies" ? (
            <AccessAWSPolicies user={user} groups={groups} />
          ) : null}
          {selectedAccess === "resources" ? (
            <AccessAWSResources user={user} groups={groups} />
          ) : null}
        </Stack>
      </Stack>
    </Box>
  );
};

export default AWSUserDetails;
