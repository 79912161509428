//export const NGROK = "https://uidev2.whiteswansec.io";

//export const NGROK = "https://ramkidev.whiteswansec.io";
//export const NGROK = "https://bharatdev.whiteswansec.io";
//export const NGROK = "https://amitdev.whiteswansec.io";

//export const NGROK = "https://phanydev1.whiteswansec.io";

export const NGROK = `https://${window.location.hostname}${window.location.port}`;

const ngrokWithoutProtocol = NGROK.slice(8);
//export const authNGROK = `https://auth-${ngrokWithoutProto
