import {
  Button,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { myLocalStorage } from "../../../components/StorageHelper";
import axios from "axios";
import { NGROK } from "../../../APIs";
import { sortProfiles } from "../../../services/Helpers";
import SettingsIcon from "@mui/icons-material/Settings";
import CategoryIcon from "@mui/icons-material/Category";
import InfoIcon from "@mui/icons-material/Info";

const ProfileTableRow = ({
  profile,
  updateProfileOperatingMode,
  selectedTenant,
  setSelectedProfile,
  setProfilesList,
}) => {
  const navigate = useNavigate();

  const updateDefaultTime = async (newTime) => {
    const replaceProfileWithUpdated = (currentProfile) => {
      setProfilesList((prev) => {
        const listWithoutCurrentProfile = prev.map((profile) =>
          profile.id !== currentProfile.id
            ? profile
            : { ...currentProfile, defaultTime: newTime },
        );
        const sortedProfilesList = sortProfiles(listWithoutCurrentProfile);

        return sortedProfilesList;
      });
    };

    try {
      await axios.put(`${NGROK}/api/profiles/update-time`, {
        profileId: profile.id,
        defaultTime: newTime,
      });
      replaceProfileWithUpdated(profile);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteProfile = async () => {
    try {
      const response = await axios.delete(
        `${NGROK}/api/profiles/delete-copied/${profile.id}`,
      );

      if (response.data) {
        setProfilesList((prev) =>
          prev.filter((deletedProfile) => profile.id !== deletedProfile.id),
        );
      }
    } catch (error) {
      console.error(error, "error");
    }
  };

  return (
    <TableRow key={profile.id} sx={{ border: 1 }}>
      <TableCell
        sx={{ width: "100px" }}
        component="td"
        scope="row"
        title={profile.name}
      >
        <Typography component={"span"} fontSize={14}>
          {profile.name}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Select
          sx={{ minWidth: "80px" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={profile.mode ? profile.mode : " "}
          size="small"
          onChange={(event) => updateProfileOperatingMode(event, profile)}
        >
          <MenuItem value={"AUDITING"}>AUDITING</MenuItem>
          <MenuItem value={"ENFORCING"}>ENFORCING</MenuItem>
          <MenuItem value={"DISABLED"}>DISABLED</MenuItem>
          <MenuItem value={"INHERITED_FROM_TENANT"}>
            INHERITED {`(${selectedTenant.mode})`}
          </MenuItem>
          );
        </Select>
      </TableCell>
      <TableCell>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={profile.defaultTime || 180}
          onChange={(e) => updateDefaultTime(e.target.value)}
        >
          <MenuItem value={60}>60 min</MenuItem>
          <MenuItem value={120}>120 min</MenuItem>
          <MenuItem value={180}>180 min</MenuItem>
          <MenuItem value={240}>240 min</MenuItem>
        </Select>
      </TableCell>
      <TableCell
        align="center"
        sx={{ width: "120px" }}
        component="td"
        scope="row"
      >
        <Button
          startIcon={<InfoIcon />}
          size="small"
          variant="outlined"
          onClick={() => {
            setSelectedProfile(profile);
            navigate("resources", {
              state: { defaultProfile: profile, selectedTenant },
            });
          }}
        >
          <Typography component={"span"} fontSize={14}>
            Details
          </Typography>
        </Button>
      </TableCell>
      <TableCell
        align="center"
        sx={{ width: "150px" }}
        component="td"
        scope="row"
      >
        <Button
          startIcon={<CategoryIcon />}
          size="small"
          variant="outlined"
          onClick={() => {
            setSelectedProfile(profile);
            myLocalStorage.setItem("selectedProfile", profile);
            navigate("categories", {
              state: { defaultProfile: profile, selectedTenant },
            });
          }}
        >
          <Typography component={"span"} fontSize={14}>
            Categories
          </Typography>
        </Button>
      </TableCell>
      <TableCell
        align="center"
        sx={{ width: "150px" }}
        component="td"
        scope="row"
      >
        <Button
          startIcon={<SettingsIcon />}
          size="small"
          variant="outlined"
          onClick={() => {
            setSelectedProfile(profile);
            myLocalStorage.setItem("selectedProfile", profile);
            navigate("profileSettings", {
              state: { defaultProfile: profile, selectedTenant },
            });
          }}
        >
          <Typography component={"span"} fontSize={14}>
            Settings
          </Typography>
        </Button>
      </TableCell>

      <TableCell
        align="center"
        sx={{ width: "150px" }}
        component="td"
        scope="row"
      >
        <Button
          disabled={!profile.canBeDeleted}
          variant="outlined"
          color="error"
          onClick={deleteProfile}
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ProfileTableRow;
