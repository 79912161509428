import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useUserStore from "../../../../services/userStore";
import AWSRequestResourceAccessForm from "./AWSRequestResourceAccessForm";
import useNotificationsStore from "../../../../services/notificationStore";
import TimerForAWS from "../../../components/TimerForAWS";
import { awsIcons } from "../AWSIcons";

const AccessEc2Resources = ({
  publishedEc2Instances,
  setPublishedEc2Instances,
  user,
}) => {
  const [selectedResource, setSelectedResource] = useState(null);
  const [onAWSRequestResourceAccessForm, setOnAWSRequestResourceAccessForm] =
    useState(false);
  const { role } = useUserStore((state) => state.user);
  const { userAWSNotifications } = useNotificationsStore();

  const [timedResourcesWithoutAccess, setTimedResourcesWithoutAccess] =
    useState([]);

  const handleClickRequestForm = (ec2) => {
    setSelectedResource(ec2);
    setOnAWSRequestResourceAccessForm(true);
  };

  const fetchResourceLevel = userAWSNotifications?.filter(
    (userAWSNotification) =>
      userAWSNotification.accessType === "resources" &&
      userAWSNotification.requestStatus === "APPROVED",
  );

  const checkAccessTiming = (accessARN) => {
    return fetchResourceLevel.find((data) => data.accessARN === accessARN);
  };

  useEffect(() => {
    console.log(timedResourcesWithoutAccess);
  }, [timedResourcesWithoutAccess]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            height: "fit-content",
            "& th": {
              background: "#233044",
              color: "#fff",
            },
            "& td, & th": {
              border: "1px solid #233044",
              fontSize: "18px",
            },
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Status</TableCell>
              {role === "TENANT_USER" ? (
                <TableCell>Action</TableCell>
              ) : (
                <TableCell>Access</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {publishedEc2Instances?.length > 0 ? (
              publishedEc2Instances.map((ec2, index) => {
                const accessData = checkAccessTiming(ec2.instanceArn);

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {awsIcons.instances}
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {" "}
                            {ec2.instanceName}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>{ec2.availabilityZone}</TableCell>
                    <TableCell>{ec2.instanceState}</TableCell>
                    {role === "TENANT_USER" ? (
                      accessData ? (
                        <TableCell>
                          <TimerForAWS
                            accessTimeOverAt={accessData.accessTimeOverAt}
                            resourceId={ec2.instanceArn}
                            selectedTime={accessData.selectedTime}
                            setTimedResourcesWithoutAccess={
                              setTimedResourcesWithoutAccess
                            }
                          />
                        </TableCell>
                      ) : (
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleClickRequestForm(ec2)}
                          >
                            Request Access
                          </Button>
                        </TableCell>
                      )
                    ) : accessData ? (
                      <TableCell>
                        <TimerForAWS
                          accessTimeOverAt={accessData.accessTimeOverAt}
                          resourceId={ec2.instanceArn}
                          selectedTime={accessData.selectedTime}
                          setTimedResourcesWithoutAccess={
                            setTimedResourcesWithoutAccess
                          }
                        />
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Button variant="outlined" color="primary" disabled>
                          UnAuthorized
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    No Resource available.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedResource && onAWSRequestResourceAccessForm && (
        <AWSRequestResourceAccessForm
          open={onAWSRequestResourceAccessForm}
          onClose={() => {
            setOnAWSRequestResourceAccessForm(false);
            setSelectedResource(null);
          }}
          data={selectedResource}
          title={selectedResource?.instanceName}
          user={user}
          arn={selectedResource?.instanceArn}
          from={"resources"}
        />
      )}
    </>
  );
};

export default AccessEc2Resources;
