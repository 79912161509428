import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  CircularProgress,
  TextField,
  Autocomplete,
} from "@mui/material";
import EC2Icon from "@mui/icons-material/CloudCircle";
import S3Icon from "@mui/icons-material/CloudUpload";
import LambdaIcon from "@mui/icons-material/Functions";
import IAMIcon from "@mui/icons-material/Lock";
import SGIcon from "@mui/icons-material/Shield";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import { NGROK } from "../../../APIs";
import EC2Instances from "./components/EC2Instances";
import EC2Details from "./components/EC2Details";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";
import S3Buckets from "./components/S3Buckets";
import { awsIcons } from "./AWSIcons";
import RDSInstances from "./components/RDSInstances";

const awsResources = [
  {
    id: "EC2",
    name: "EC2 Instances",
    icon: awsIcons.instances,
    description: "Virtual servers in the cloud for running applications.",
  },
  {
    id: "S3",
    name: "S3 Buckets",
    icon: awsIcons.buckets,
    description: "Scalable storage service for storing and retrieving data.",
  },
  {
    id: "RDS",
    name: "RDS",
    icon: awsIcons.rdsLarge,
    description:
      "Amazon RDS (Relational Database Service) is a managed database service offered by Amazon Web Services",
  },
  /* {
    id: "Lambda",
    name: "Lambda Functions",
    icon: <LambdaIcon />,
    description:
      "Serverless compute service that runs code in response to events.",
  },
  {
    id: "IAMUsers",
    name: "IAM Users",
    icon: <PersonIcon />,
    description:
      "AWS identities with specific permissions to manage resources.",
  },
  {
    id: "IAMRoles",
    name: "IAM Roles",
    icon: <IAMIcon />,
    description: "Roles that grant specific permissions to AWS services.",
  },
  {
    id: "Security",
    name: "Security Groups",
    icon: <SGIcon />,
    description:
      "Virtual firewalls for controlling inbound and outbound traffic.",
  }, */
];

const AWSResources = () => {
  const [selectedResource, setSelectedResource] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDetailsTab, setSelectedDetailsTab] = useState(0);

  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const userData = useUserStore((state) => state.user);
  const [tenantList, setTenantList] = useState([]);
  const [ec2Datas, setEc2Datas] = useState([]);
  const [s3Datas, setS3Datas] = useState([]);
  const [rdsDatas, setRdsDatas] = useState([]);

  const fetchInstanceFortheResource = async (resource) => {
    setSelectedResource(resource);
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredAwsResources = awsResources.filter((resource) =>
    resource.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleDetailsTabChange = (event, newValue) => {
    setSelectedDetailsTab(newValue);
  };

  useEffect(() => {
    if (awsResources.length > 0) {
      setSelectedResource(awsResources[0]);
      fetchInstanceFortheResource(awsResources[0]);
    }
  }, []);

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  return (
    <>
      <Box p={2}>
        <Stack direction={"column"} spacing={5} mb={3}>
          <Typography variant="h5">AWS Resources</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {tenantList?.length > 0 ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={selectedTenantName ? selectedTenantName : " "}
                options={tenantList.map((tenant) => tenant.tenantName)}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Tenant List" />
                )}
                onChange={(e, value) => {
                  handleTenantChange(value);
                }}
              />
            ) : null}
          </Box>
        </Stack>
        <Stack direction="row" spacing={4}>
          <Box width="30%">
            <TableContainer component={Paper}>
              <Table
                sx={{
                  height: "fit-content",
                  "& th": {
                    background: "#233044",
                    color: "#fff",
                  },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "18px",
                  },
                }}
                size="large"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Resource Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={1}>
                      <TextField
                        variant="outlined"
                        placeholder="Search Resources"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                  {filteredAwsResources.map((resource) => (
                    <TableRow
                      key={resource.name}
                      selected={resource === selectedResource}
                      onClick={() => fetchInstanceFortheResource(resource)}
                      sx={{
                        "&:hover": {
                          background: "#f5f5f5",
                          cursor: "pointer",
                        },
                        "& td, & th": {
                          border: "1px solid #233044",
                          fontSize: "8px",
                        },
                        "&.Mui-selected": {
                          background: "#233044 !important",
                          "& td, & th": {
                            color: "#fff",
                          },
                        },
                      }}
                    >
                      <TableCell>
                        <Stack
                          direction={"row"}
                          spacing={5}
                          display="flex"
                          alignItems="center"
                        >
                          <div>{resource.icon}</div>

                          <Box ml={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: 18,
                              }}
                            >
                              {resource.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: 16,
                              }}
                            >
                              {resource.description}
                            </Typography>
                          </Box>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box width="70%">
            {selectedResource?.id === "EC2" && (
              <EC2Instances
                ec2Datas={ec2Datas}
                setEc2Datas={setEc2Datas}
                tenantName={selectedTenantName}
              />
            )}
            {selectedResource?.id === "S3" && (
              <S3Buckets
                s3Datas={s3Datas}
                setS3Datas={setS3Datas}
                tenantName={selectedTenantName}
              />
            )}
            {selectedResource?.id === "RDS" && (
              <RDSInstances
                rdsDatas={rdsDatas}
                setRdsDatas={setRdsDatas}
                tenantName={selectedTenantName}
              />
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default AWSResources;
